const state = () => ({
  alert: {
    status: "",
    message: "",
  },
});

const getters = {
  getAlert: (state) => {
    return state.alert;
  },
};

const actions = {
  async store({ commit }, data) {
    // this._vm.$notify("text");

    commit("setAlert", data);
    setTimeout(function () {
      commit("setAlert", {
        message: "",
        errorMessages: [],
      });
    }, 5000);
  },
};

const mutations = {
  async setAlert(state, data) {
    await (state.alert = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
