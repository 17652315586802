<template>
  <div></div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {},
};
</script>
<style scoped>
#downloading {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  position: absolute;
  z-index: 1245603;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* opacity: .2; */
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
#downloading > div {
  text-align: center;
  background-color: #fff;
  left: auto;
  right: auto;
  width: 600px;
  height: 150px;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #fd1414; /* Blue */
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.text {
  margin-top: 20px;
  font-size: 18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
