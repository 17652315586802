// store/modules/media.js
import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "medias";
const state = {
  medias: [],
  media: {},
};

const getters = {
  getMedias: (state) => state.medias,
  getMedia: (state) => state.media,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch medias from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_MEDIAS", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching medias:", error);
    }
  },

  async find({ commit }, id) {
    const response = await axios.get(`${api}/${id}`);
    commit("SET_MEDIA", response.data);
  },

  async create({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${api}`, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_MEDIAS", [...state.medias, response.data]);
        router.push({
          name: "medias-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async update({ commit }, data) {
    // Make an API request to update a media and commit mutations.
  },
  async delete({ commit }, id) {
    // Make an API request to delete a media and commit mutations.
  },
};

const mutations = {
  SET_MEDIAS: (state, medias) => (state.medias = medias),
  SET_MEDIA: (state, media) => (state.media = media),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
