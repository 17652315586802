import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Dashboard",
      layout: "dashboard",
    },
    component: () => import("../views/Dashboard.vue"),
    beforeEach: (to, from, next) => {
      next({ name: "dashboard" });
    },
  },

  {
    path: "*",
    name: "404",
    meta: {
      title: "404",
      layout: "dashboard",
    },
    component: () => import("../views/errors/404.vue"),
  },

  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Dashboard",
      layout: "dashboard",
    },
    component: () => import("../views/Dashboard.vue"),
  },

  //about
  {
    path: "/about",
    name: "about",
    meta: {
      is_auth: false,
      title: "About",
      layout: "default",
    },
    component: () => import("../views/About.vue"),
  },
  //login
  {
    path: "/login",
    name: "login",
    meta: {
      is_auth: false,
      title: "Login",
      layout: "login",
    },
    component: () => import("../views/auth/Login.vue"),
  },

  // news  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "news",
      layout: "dashboard",
    },
    path: "/news",
    name: "news",
    component: () => import("../layouts/index/NewsIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'actualités",
          layout: "dashboard",
        },
        path: "/",
        name: "news-index",
        component: () => import("../views/news/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'actualités",
          layout: "dashboard",
        },
        path: "index",
        name: "news-index",
        component: () => import("../views/news/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Affichage d'une actualité",
          layout: "dashboard",
        },
        path: "details/:id",
        name: "news-details",
        component: () => import("../views/news/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier l'actualité",
          layout: "dashboard",
        },
        path: "edit/:id",
        name: "news-edit",
        component: () => import("../views/news/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer une actualité",
          layout: "dashboard",
        },
        path: "create",
        name: "news-create",
        component: () => import("../views/news/Create.vue"),
      },
    ],
  },

  // partners  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "partners",
      layout: "dashboard",
    },
    path: "/partners",
    name: "partners",
    component: () => import("../layouts/index/PartnersIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de partenaires",
          layout: "dashboard",
        },
        path: "index",
        name: "partners-index",
        component: () => import("../views/partners/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un partenaire",
          layout: "dashboard",
        },
        path: "create",
        name: "partners-create",
        component: () => import("../views/partners/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le partenaire",
          layout: "dashboard",
        },
        path: "edit/:id",
        name: "partners-edit",
        component: () => import("../views/partners/Edit.vue"),
      },
    ],
  },

  // messages  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "messages",
      layout: "dashboard",
    },
    path: "/messages",
    name: "messages",
    component: () => import("../layouts/index/MessagesIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de messages",
          layout: "dashboard",
        },
        path: "index",
        name: "messages-index",
        component: () => import("../views/messages/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Detail de messages",
          layout: "dashboard",
        },
        path: "details/:id",
        name: "messages-details",
        component: () => import("../views/messages/Details.vue"),
      },
    ],
  },

  // medias  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "medias",
      layout: "dashboard",
    },
    path: "/medias",
    name: "medias",
    component: () => import("../layouts/index/MediasIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Médiathèque",
          layout: "dashboard",
        },
        path: "index",
        name: "medias-index",
        component: () => import("../views/medias/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer la médiathèque",
          layout: "dashboard",
        },
        path: "create",
        name: "medias-create",
        component: () => import("../views/medias/Create.vue"),
      },
    ],
  },

  // projects  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "projects",
      layout: "dashboard",
    },
    path: "/projects",
    name: "projects",
    component: () => import("../layouts/index/ProjectsIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de projets",
          layout: "dashboard",
        },
        path: "index",
        name: "projects-index",
        component: () => import("../views/projects/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Affichage d'un projet",
          layout: "dashboard",
        },
        path: "details/:slug",
        name: "projects-details",
        component: () => import("../views/projects/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le projet",
          layout: "dashboard",
        },
        path: "edit/:slug",
        name: "projects-edit",
        component: () => import("../views/projects/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un projet",
          layout: "dashboard",
        },
        path: "create",
        name: "projects-create",
        component: () => import("../views/projects/Create.vue"),
      },
    ],
  },

  // presses  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Presse",
      layout: "dashboard",
    },
    path: "/presses",
    name: "presses",
    component: () => import("../layouts/index/PressIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de presse",
          layout: "dashboard",
        },
        path: "/",
        name: "presses-index",
        component: () => import("../views/presses/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de presse",
          layout: "dashboard",
        },
        path: "index",
        name: "presses-index",
        component: () => import("../views/presses/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le projet",
          layout: "dashboard",
        },
        path: "edit/:id",
        name: "presses-edit",
        component: () => import("../views/presses/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un projet",
          layout: "dashboard",
        },
        path: "create",
        name: "presses-create",
        component: () => import("../views/presses/Create.vue"),
      },
    ],
  },

  // carouseles  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "carouseles",
      layout: "dashboard",
    },
    path: "/carouseles",
    name: "carouseles",
    component: () => import("../layouts/index/CarouselIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de projets",
          layout: "dashboard",
        },
        path: "/",
        name: "carouseles-index",
        component: () => import("../views/carouseles/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de projets",
          layout: "dashboard",
        },
        path: "index",
        name: "carouseles-index",
        component: () => import("../views/carouseles/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le projet",
          layout: "dashboard",
        },
        path: "edit/:id",
        name: "carouseles-edit",
        component: () => import("../views/carouseles/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un projet",
          layout: "dashboard",
        },
        path: "create",
        name: "carouseles-create",
        component: () => import("../views/carouseles/Create.vue"),
      },
    ],
  },

  // users  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "users",
      layout: "dashboard",
    },
    path: "/users",
    name: "users",
    component: () => import("../layouts/index/UserIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'utilisateurs",
          layout: "dashboard",
        },
        path: "/",
        name: "users-index",
        component: () => import("../views/users/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'utilisateurs",
          layout: "dashboard",
        },
        path: "index",
        name: "users-index",
        component: () => import("../views/users/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Affichage d'un utilisateur",
          layout: "dashboard",
        },
        path: "details/:id",
        name: "users-details",
        component: () => import("../views/users/Details.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier l'actualité",
          layout: "dashboard",
        },
        path: "edit/:id",
        name: "users-edit",
        component: () => import("../views/users/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer une actualité",
          layout: "dashboard",
        },
        path: "create",
        name: "users-create",
        component: () => import("../views/users/Create.vue"),
      },
    ],
  },

  // contact  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "contact",
      layout: "dashboard",
    },
    path: "/contact",
    name: "contact",
    component: () => import("../layouts/index/ContactIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste de partenaires",
          layout: "dashboard",
        },
        path: "index",
        name: "contact-index",
        component: () => import("../views/contact/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un partenaire",
          layout: "dashboard",
        },
        path: "create",
        name: "contact-create",
        component: () => import("../views/contact/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le partenaire",
          layout: "dashboard",
        },
        path: "edit/:id",
        name: "contact-edit",
        component: () => import("../views/contact/Edit.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function guard(to, from, next, authData, userRole) {
  // console.log(authData);
  if (to.meta && to.meta.is_auth == true) {
    if (authData != null && authData.reference != undefined) {
      if (to.meta.is_admin == true) {
        return next();
      }
    }
  }
  if (to.name == "login") {
    console.log("route login");
    return next();
  }
  next();
}

router.beforeEach((to, from, next) => {
  let authData = store.getters["auth/getAuthData"];
  let userRole = store.getters["auth/getUserRole"];
  return guard(to, from, next, authData, userRole);
});

export default router;
