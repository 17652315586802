// store/modules/partner.js
import constant from "../../constant";
import axios from "axios";
import router from '../../router'
const api = constant.api + "partners";
const state = {
  partners: [],
  partner: {},
};

const getters = {
  getPartners: (state) => state.partners,
  getPartner: (state) => state.partner,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch partners from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_PARTNERS", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching partners:", error);
    }
  },

  async find({ commit }, id) {
    const response = await axios.get(`${api}/${id}`);
    commit("SET_PARTNER", response.data);
  },

  async create({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${api}`, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_PARTNERS", [...state.partners, response.data]);
        await router.push({
          name: "partners-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async update({ commit }, data) {
    // Make an API request to update a partner and commit mutations.
  },
  async delete({ commit }, id) {
    // Make an API request to delete a partner and commit mutations.
  },
};

const mutations = {
  SET_PARTNERS: (state, partners) => (state.partners = partners),
  SET_PARTNER: (state, partner) => (state.partner = partner),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
