// store/modules/press.js
import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "presses";

const state = {
  presses: [],
  press: {},
};

const getters = {
  getPresses: (state) => state.presses,
  getPress: (state) => state.press,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch presses from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_PRESSES", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching presses:", error);
    }
  },

  async find({ commit }, id) {
    const response = await axios.get(`${api}/${id}`);
    commit("SET_PRESS", response.data);
  },

  async create({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${api}`, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_PRESSES", [...state.presses, response.data.data]);
        await router.push({
          name: "presses-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async update({ commit, dispatch }, data) {
    try {
      const response = await axios.put(`${api}` + "/" + data.slug, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_PRESSES", [...state.presses, response.data.data]);
        router.push({
          name: "presses-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async delete({ commit, dispatch }, id) {
    try {
      const response = await axios.delete(`${api}` + "/" + id);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_PRESSES",  response.data.data);
      }
    } catch (error) {
      await dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_PRESSES: (state, presses) => (state.presses = presses),
  SET_PRESS: (state, press) => (state.press = press),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
