<template>
  <div>
    <button
      class="menu-button"
      @click="toggleSidebar"
      :class="{ open: isSidebarOpen }"
    >
      <i class="bi bi-list text-white" v-if="!isSidebarOpen"></i>
      <i class="bi bi-x-lg text-white" v-else></i>
    </button>
    <div class="s-layout__sidebar" :class="{ hide: !isSidebarOpen }">
      <div class="s-sidebar__trigger">
        <span>
          <img
            src="../../assets/logo.png"
            alt=""
            :style="{
              height: '5rem !important',
            }"
            class=""
          />
        </span>
        <!-- <div>OAG</div> -->
      </div>

      <nav class="s-sidebar__nav">
        <ul class="">
          <!-- dashboard -->
          <li>
            <router-link
              :to="{ name: 'dashboard' }"
              :class="$route.name.includes('dashboard') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-back"></i>
              <div>Dashboard</div>
            </router-link>
          </li>

          <!-- carousel -->
          <li>
            <router-link
              :to="{ name: 'carouseles' }"
              :class="$route.name.includes('carouseles') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-repeat"></i>
              <div>Carrousel</div>
            </router-link>
          </li>

          <!-- news -->
          <li>
            <router-link
              :to="{ name: 'news-index' }"
              :class="$route.name.includes('news') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-card-list"></i>
              <div>Actualités</div>
            </router-link>
          </li>

          <li>
            <router-link
              :to="{ name: 'messages-index' }"
              :class="$route.name.includes('messages') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-envelope-fill"></i>
              <div>Messages</div>
            </router-link>
          </li>

          <li>
            <router-link
              :to="{ name: 'projects-index' }"
              :class="$route.name.includes('projects') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-journal-bookmark-fill"></i>
              <div>Projets</div>
            </router-link>
          </li>

          <li>
            <router-link
              :to="{ name: 'presses-index' }"
              :class="$route.name.includes('presses') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-journal-bookmark-fill"></i>
              <div>Presse</div>
            </router-link>
          </li>

          <li>
            <router-link
              :to="{ name: 'medias-index' }"
              :class="$route.name.includes('medias') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-collection-play-fill"></i>
              <div>Médiathèques</div>
            </router-link>
          </li>

          <li>
            <router-link
              :to="{ name: 'partners-index' }"
              :class="$route.name.includes('partners') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-file-earmark-break-fill"></i>
              <div>Partenaires</div>
            </router-link>
          </li>

          <li>
            <router-link
              :to="{ name: 'users-index' }"
              :class="$route.name.includes('users') ? 'active' : ''"
              class="s-sidebar__nav-link"
            >
              <i class="bi bi-back"></i>
              <div>Utilisateurs</div>
            </router-link>
          </li>

          <div class="my-5"></div>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      settings_display: false,
      security_display: false,
      isSidebarOpen: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
  },
};
</script>
<style scoped>
.bi {
  font-size: 18px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 767px) {
  .bi {
    font-size: 32px !important;
    margin: 0;
  }
}
</style>
