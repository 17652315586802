<template>
  <div
    v-if="alert.message != ''"
    class="_alert rounded"
    :class="
      alert.status == 'success'
        ? 'bg-success text-white'
        : alert.status == 'error'
        ? 'bg-danger text-white'
        : alert.status == 'warning'
        ? 'bg-warning text-white'
        : alert.status == 'info'
        ? 'bg-info text-white'
        : alert.status == 'primary'
        ? 'bg-primary text-white'
        : ''
    "
  >
    <div class="p-1 row align-items-center">
      <!-- <div class="col-auto p-0 m-0">
        <i class="bi bi-chevron-right p-0 m-0"></i>
      </div> -->
      <div v-if="alert.status" class="col-auto">
        <i
          v-if="alert.status == 'success'"
          class="bi bi-check2-all text-white fs-5"
        ></i>

        <i
          v-if="alert.status == 'error'"
          class="bi bi-check2-all text-white fs-5"
        ></i>
      </div>
      <div class="col p-0" v-html="alert.message"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("notification", {
      alert: "getAlert",
    }),
  },
};
</script>
<style scoped>
._alert {
  width: auto !important;
  min-width: 300px !important;
  right: 10px;
  padding-right: 30px;
  top: 80px;
  /* border: 1px solid red; */
  height: 45px;
  /* padding: 15px 10px; */
  display: flex;
  align-items: center;
  text-align: start;
  /* margin-top: -5px; */
  position: absolute;
}
</style>
