// store/modules/newsArticle.js
import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "news";
const state = {
  newsArticles: [],
  newsArticle: {},
};

const getters = {
  getNewsArticles: (state) => state.newsArticles,
  getNewsArticle: (state) => state.newsArticle,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch newsArticles from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_NEWS_ARTICLES", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching newsArticles:", error);
    }
  },

  async find({ commit }, id) {
    const response = await axios.get(`${api}/${id}`);
    commit("SET_NEWS_ARTICLE", response.data);
  },

  async create({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${api}`, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_NEWS_ARTICLES", [
          ...state.newsArticles,
          response.data.data,
        ]);
        await router.push({
          name: "news-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async update({ commit, dispatch }, data) {
    try {
      const response = await axios.put(`${api}` + "/" + data.slug, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_NEWS_ARTICLES", [
          ...state.newsArticles,
          response.data.data,
        ]);
        router.push({
          name: "news-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },
  
  async delete({ commit, dispatch }, id) {
    try {
      const response = await axios.delete(`${api}` + "/" + id);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_NEWS_ARTICLES", response.data.data);
        router.push({
          name: "news-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_NEWS_ARTICLES: (state, newsArticles) =>
    (state.newsArticles = newsArticles),
  SET_NEWS_ARTICLE: (state, newsArticle) => (state.newsArticle = newsArticle),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
