// store/modules/Messages.js
import constant from "../../constant";
import axios from "axios";
const api = constant.api + "messages";
const state = {
  messages: [],
  message: {},
};

const getters = {
  getMessages: (state) => state.messages,
  getMessage: (state) => state.message,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch messages from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_MESSAGES", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching messages:", error);
    }
  },

  async find({ commit }, id) {
    const response = await axios.get(`${api}/${id}`);
    commit("SET_MESSAGE", response.data);
  },

  
};

const mutations = {
  SET_MESSAGES: (state, messages) => (state.messages = messages),
  SET_MESSAGE: (state, message) => (state.message = message),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
