import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "users";
// initial state
const state = () => ({
  all: [],
  users: [],
  user: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getUser: (state) => {
    return state.user;
  },

  getUsers: (state) => {
    return state.users;
  },
};

// actions
const actions = {
  async fetch({ commit }) {
    await axios.get(api).then((response) => {
      let users = response.data;
      commit("setUsers", users);
    });
  },

  async find({ commit }, id) {
    await axios.get(api + "/" + id).then((response) => {
      let user = response.data;
      commit("setUser", user);
    });
  },

  async create({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "users-index",
        });
      }
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.id, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        router.push({
          name: "users-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") {
        let users = response.data.data;
        commit("setUsers", users);
      }
    });
  },
};

// mutations
const mutations = {
  setUsers(state, users) {
    state.all = users;
    state.users = users;
  },
  setUser(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
