import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import auth from "./modules/auth";
import carousel from "./modules/carousel";
import contact from "./modules/contact";
import news from "./modules/news";
import notification from "./modules/notification";
import media from "./modules/media";
import message from "./modules/message";
import user from "./modules/user";
import partner from "./modules/partner";
import press from "./modules/press";
import project from "./modules/project";

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    carousel,
    contact,
    news,
    notification,
    media,
    message,
    partner,
    press,
    project,
    user,
  },
});
