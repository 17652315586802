// store/modules/carousel.js
import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "carouseles";

const state = {
  carouseles: [],
  carousel: {},
};

const getters = {
  getCarouseles: (state) => state.carouseles,
  getCarousel: (state) => state.carousel,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch carouseles from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_CAROUSELES", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching carouseles:", error);
    }
  },

  async find({ commit }, id) {
    const response = await axios.get(`${api}/${id}`);
    commit("SET_CAROUSEL", response.data);
  },

  async create({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${api}`, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_CAROUSELES", [
          ...state.carouseles,
          response.data.data,
        ]);
        await router.push({
          name: "carouseles-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async update({ commit, dispatch }, data) {
    try {
      const response = await axios.put(`${api}` + "/" + data.slug, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_CAROUSELES", [
          ...state.carouseles,
          response.data.data,
        ]);
        router.push({
          name: "carouseles-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async delete({ commit, dispatch }, id) {
    try {
      const response = await axios.delete(`${api}` + "/" + id);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_CAROUSELES", response.data.data);
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },
};

const mutations = {
  SET_CAROUSELES: (state, carouseles) => (state.carouseles = carouseles),
  SET_CAROUSEL: (state, carousel) => (state.carousel = carousel),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
