// store/modules/project.js
import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "projects";
const state = {
  projects: [],
  project: {},
};

const getters = {
  getProjects: (state) => state.projects,
  getProject: (state) => state.project,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch projects from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_PROJECTS", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching projects:", error);
    }
  },

  async find({ commit }, id) {
    const response = await axios.get(`${api}/${id}`);
    commit("SET_PROJECT", response.data.data);
  },

  async create({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${api}`, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_PROJECTS", [...state.projects, response.data.data]);
        await router.push({
          name: "projects-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async update({ commit, dispatch }, data) {
    var slug = data.get('slug');
    try {
      const response = await axios.post(`${api}/update/${slug}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }); // <-- Closing curly brace was missing here
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_PROJECTS", [...state.projects, response.data.data]);
        router.push({
          name: "projects-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  // async update({ commit, dispatch }, data) {
  //   try {
  //     const response = await axios.put(`${api}` + "/" + data.slug, data,{
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     });
  //     let message = response.data.message;
  //     let status = response.data.status;

  //     await dispatch("notification/store", { status, message }, { root: true });

  //     if (status == "success") {
  //       await commit("SET_PROJECTS", [...state.projects, response.data.data]);
  //       router.push({
  //         name: "projects-index",
  //       });
  //     }
  //   } catch (error) {
  //     dispatch(
  //       "notification/store",
  //       { status: "error", message: error },
  //       { root: true }
  //     );
  //   }
  // },
  async delete({ commit }, slug) {
     try {
       const response = await axios.delete(`${api}/${slug}`);
       let message = response.data.message;
       let status = response.data.status;

       await dispatch(
         "notification/store",
         { status, message },
         { root: true }
       );

       if (status == "success") {
         await commit("SET_PROJECTS", [...state.projects, response.data.data]);
         await router.push({
           name: "projects-index",
         });
       }
     } catch (error) {
       dispatch(
         "notification/store",
         { status: "error", message: error },
         { root: true }
       );
     }
  },
};

const mutations = {
  SET_PROJECTS: (state, projects) => (state.projects = projects),
  SET_PROJECT: (state, project) => (state.project = project),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
