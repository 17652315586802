// store/modules/contact.js
import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "contact";
const state = {
  contactInos: [],
  contactInfo: {},
};

const getters = {
  getContactInos: (state) => state.contactInos,
  getContactInfo: (state) => state.contactInfo,
};

const actions = {
  async fetch({ commit }) {
    try {
      // Make an API request to fetch contactInos from your backend.
      // For example, using Axios:
      const response = await axios.get(`${api}`);
      commit("SET_CONTACT_INFOS", response.data);
    } catch (error) {
      // Handle any errors, such as network issues or invalid responses.
      console.error("Error fetching contactInos:", error);
    }
  },

  async find({ commit }, id) {
    const response = await axios.get(`${api}/${id}`);
    commit("SET_CONTACT_INFO", response.data);
  },

  async create({ commit, dispatch }, data) {
    try {
      const response = await axios.post(`${api}`, data);
      let message = response.data.message;
      let status = response.data.status;

      await dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        await commit("SET_CONTACT_INFOS", [...state.contactInos, response.data]);
        router.push({
          name: "contact-index",
        });
      }
    } catch (error) {
      dispatch(
        "notification/store",
        { status: "error", message: error },
        { root: true }
      );
    }
  },

  async update({ commit }, data) {
    // Make an API request to update a contactInfo and commit mutations.
  },
  async delete({ commit }, id) {
    // Make an API request to delete a contactInfo and commit mutations.
  },
};

const mutations = {
  SET_CONTACT_INFOS: (state, contactInos) => (state.contactInos = contactInos),
  SET_CONTACT_INFO: (state, contactInfo) => (state.contactInfo = contactInfo),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
